import React from 'react';
import { bool, func, node, number } from 'prop-types';
import classNames from 'classnames';

import css from './PopupOpenerButton.module.css';

const PopupOpenerIcon = props => {
  const classes = classNames(css.icon, props.className);
  // extra small arrow head (down)
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="7"
      fill="none"
      viewBox="0 0 11 7"
    >
      <path stroke="#201F22" strokeOpacity="0.6" d="M1 .5l4.5 5 4.5-5"></path>
    </svg>
  );
};

const PopupOpenerButton = props => {
  const { isSelected, labelMaxWidth, toggleOpen, children } = props;

  const labelStyles = isSelected ? css.labelSelected : css.label;
  const labelMaxWidthMaybe = labelMaxWidth ? { maxWidth: `${labelMaxWidth}px` } : {};
  const labelMaxWidthStyles = labelMaxWidth ? css.labelEllipsis : null;

  return (
    <button
      className={classNames(labelStyles, labelMaxWidthStyles)}
      style={labelMaxWidthMaybe}
      onClick={() => toggleOpen()}
    >
      {children}

      <PopupOpenerIcon/>
    </button>
  );
};

PopupOpenerButton.defaultProps = {
  isSelected: false,
  labelMaxWidth: null,
};

PopupOpenerButton.propTypes = {
  isSelected: bool,
  toggleOpen: func.isRequired,
  children: node.isRequired,
  labelMaxWidth: number,
};

export default PopupOpenerButton;
